import React from 'react';

const StyledText = ({ text }) => {
    // Regular expression to find <pre> tags with any class
    const preTagRegex = /<pre[^>]*>([\s\S]*?)<\/pre>/g;

    // Function to replace <pre> tags with styled <pre> tags
    const replacePreTags = (match, code) => {
        // Split the code content by lines
        const lines = code.split('\n');
        
        // Wrap each line with a styled <div>
        const styledLines = lines.map(line => `<div style="background-color: black; color: white; padding: 5px;">${line}</div>`).join('');

        // Wrap the styled lines in a <pre> tag to maintain formatting
        return `<pre>${styledLines}</pre>`;
    };

    // Replace <pre> tags in the text with styled <pre> tags
    const styledText = text.replace(preTagRegex, replacePreTags);

    // Return the HTML content with styled <pre> tags
    return <div className='text-black dark:text-white' dangerouslySetInnerHTML={{ __html: styledText }} />;
};

export default StyledText;
