//BRAND
export const name = 'Solo School';
export const company = 'the solo school';

// export const websiteURL = 'http://localhost:3000';
// export const serverURL = 'http://localhost:8888';



export const websiteURL = 'https://soloschool.app';
export const serverURL = 'https://soloschool-ai.vercel.app';


export const logo = 'https://i.ibb.co/7YhXb3C/Black-White-Minimalist-Logo-2.png';
export const razorpayEnabled = false;
export const paypalEnabled = true;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 4;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 40;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Sai Ko";
export const profession = 'Software Dev.';
export const photoURL = 'https://i.ibb.co/xLfChQ2/user-1251070.png';

//PAYPAL
export const paypalPlanIdOne = "P-17K84813C24785917MYROPWY";
// export const paypalPlanIdOne = "P-08X79171G4338330VMYTJY3Y"; //trail
export const paypalPlanIdTwo = "P-88Y77982UV9709605MYSZPQY";

//sandbox
// export const paypalPlanIdOne = "P-3WM00141LA793880LMYR7IEY";
// export const paypalPlanIdTwo = "P-1D000469XH5418611MYR7LLI";




//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";